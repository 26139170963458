<template>
    <ion-page>
        <ion-toolbar
            style="--background: #03a9f4; color: white; --min-height: 36px"
            class="custom-toolbar"
        >
            <div class="row align-center">
                <slot name="before">
                    <div class="shrink">
                        <DxButton
                            type="default"
                            icon="mdi mdi-chevron-left"
                            class="ml-1"
                            @click="$router.go(-1)"
                        />
                    </div>
                </slot>
                <slot name="center">
                    <div class="grow text-xs-center">CHỌN CHỖ BÁN VÉ</div>
                </slot>
                <slot name="after">
                    <div class="shrink" style="opacity: 0">
                        <DxButton
                            icon="mdi mdi-filter-variant"
                            type="default"
                            @click="PopupLocDuLieu = true"
                        />
                    </div>
                </slot>
            </div>
        </ion-toolbar>

        <ion-content :scroll-events="false">
            <div class="px-2 pt-2 pb-3">
                <DxValidationGroup ref="formValidation">
                    <div class="row align-center">
                        <div class="xs6 mr-2">
                            <DxSelectBox
                                v-model="DiemXuatPhat"
                                :dataSource="DanhSachDiemDungCuaLoTrinh"
                                displayExpr="tenDiemXuong"
                                valueExpr="guidDiemXuong"
                                placeholder="Chọn địa điểm"
                                :searchEnabled="true"
                                validationMessageMode="always"
                                styling-mode="underlined"
                                :onValueChanged="ChonDiemXuatPhat"
                            ></DxSelectBox>
                        </div>
                        <i class="mdi mdi-transfer-right mx-2"></i>
                        <div class="xs6 ml-2">
                            <DxSelectBox
                                v-model="DiemXuong"
                                :dataSource="DanhSachDiemDungCuaLoTrinh"
                                displayExpr="tenDiemXuong"
                                valueExpr="guidDiemXuong"
                                placeholder="Chọn địa điểm"
                                :searchEnabled="true"
                                validationMessageMode="always"
                                styling-mode="underlined"
                                :onValueChanged="ChonDiemXuong"
                            ></DxSelectBox>
                        </div>
                    </div>
                </DxValidationGroup>

                <div class="row" :class="DanhSachTangXe.length > 1 ? ' mt-2' : ' mt-4'">
                    <div class="xs12">
                        <div
                            class="row justify-center mb-2"
                            v-if="DanhSachTangXe.length > 1"
                        >
                            <div
                                v-for="(item, index) in DanhSachTangXe"
                                :key="index"
                                :style="`width: 24px;
                                            height: 24px;
                                            padding: 4px;
                                            text-align: center;
                                            border-radius:50px;
                                            margin: 0 8px;
                                            ${
                                                !item.active
                                                    ? 'border:1px solid #707070'
                                                    : ''
                                            }`"
                                :class="`${
                                    item.active ? 'primary--bg white--text' : 'white-bg'
                                }`"
                                @click="ChonTangXe(item, index)"
                            >
                                {{
                                    item.TenTang.replace("Tầng ", "").trim() ||
                                    item.TenTang
                                }}
                            </div>
                        </div>
                        <div
                            class="row frame-so-do-cho justify-center"
                            :class="DanhSachTangXe.length > 1 ? 'xe-nhieu-tang' : ''"
                        >
                            <SoDoChoVue
                                :index="TangXe.index"
                                :TangXe="TangXe"
                                :ShowCheckBox="false"
                                :EnableSelect="true"
                            />
                        </div>
                    </div>
                </div>

                <div
                    class="row mt-3 justify-space-between"
                    style="
                        position: fixed;
                        bottom: 0;
                        border-top: 1px solid #dadce0;
                        left: 0;
                        padding: 12px;
                        background: #fff;
                    "
                >
                    <div class="xs6 mr-2">
                        <DxButton
                            text="BÁN VÉ GHẾ PHỤ"
                            type="default"
                            styling-mode="outlined"
                            @click="BanVeGhePhu()"
                            style="width: 100%"
                        />
                    </div>
                    <div class="xs6 ml-2">
                        <DxButton
                            text="BÁN VÉ"
                            type="default"
                            styling-mode="contained"
                            style="width: 100%"
                            :disabled="disabledBanVe"
                            @click="BanVe()"
                        />
                    </div>
                </div>
            </div>

            <PopupVue
                height="auto"
                v-if="ifPopupXacNhanBanVuotSoVeToiDa"
                v-model:dialog="PopupXacNhanBanVuotSoVeToiDa"
                heightScrollView="100%"
                title=""
                :useButtonLeft="false"
                :useButtonRight="false"
                class="popup-padding-16"
            >
                <template #content>
                    <NotificationVue
                        :Params="ParamXacNhanBanVuotSoVeToiDa"
                        @click="XacNhanBanVuotSoVeToiDa"
                    />
                </template>
            </PopupVue>
        </ion-content>
    </ion-page>
</template>

<script>
import { DxValidationGroup } from "devextreme-vue/validation-group";
import { IonContent, IonPage, IonToolbar, onIonViewWillEnter } from "@ionic/vue";
import { DxButton, DxSelectBox } from "devextreme-vue";
import SoDoChoVue from "./components/SoDoCho.vue";
import NotificationVue from "@sonphat/common-v1/components/Notification.vue";
import PopupVue from "@/components/_Common/Popup.vue";
import { mapMutations } from "vuex";
export default {
    components: {
        DxValidationGroup,
        IonContent,
        IonPage,
        DxButton,
        IonToolbar,
        DxSelectBox,
        SoDoChoVue,
        NotificationVue,
        PopupVue,
    },
    data() {
        return {
            TangXe: {},
            queryHeader: {},
            DiemXuatPhat: null,
            DiemXuong: null,
            DangChonTangXe: false,
            ifPopupXacNhanBanVuotSoVeToiDa: false,
            PopupXacNhanBanVuotSoVeToiDa: false,
            ParamXacNhanBanVuotSoVeToiDa: {
                state: "Warning",
                title: "Thông báo!",
                message_title: "Bạn đã bán hết số vé tối đa được phép tại chuyến đi này!",
                message: "Bạn có muốn bán vượt số vé tối đa?",
            },
        };
    },
    computed: {
        DanhSachTangXe() {
            return this.$store.state.ChuyenDi.DanhSachTangXe;
        },
        SoDoCho() {
            return this.$store.state.ChuyenDi.SoDoCho;
        },
        DanhSachDiemDungCuaLoTrinh() {
            return this.$store.state.ChuyenDi.DanhSachDiemDungCuaLoTrinh;
        },
        DanhSachChoDangChonTheoXe() {
            return this.$store.state.ChuyenDi.DanhSachChoDangChonTheoXe;
        },
        reloadData() {
            return this.$store.state.ChuyenDi.reloadData;
        },
        DiemXuatPhatDangChon() {
            let item = this.DanhSachDiemDungCuaLoTrinh.filter(
                (e) => e.guidDiemXuong == this.DiemXuatPhat,
            );
            return item.length > 0 ? item[0] : null;
        },
        DiemXuongDangChon() {
            let item = this.DanhSachDiemDungCuaLoTrinh.filter(
                (e) => e.guidDiemXuong == this.DiemXuong,
            );
            return item.length > 0 ? item[0] : null;
        },
        DanhSachChoNgoiBanVe: {
            get() {
                return this.$store.state.ChuyenDi.DanhSachChoNgoiBanVe;
            },
            set(data) {
                this.$store.commit("ChuyenDi/Set", {
                    key: "DanhSachChoNgoiBanVe",
                    data: data,
                });
            },
        },
        disabledBanVe() {
            let check = this.DanhSachChoDangChonTheoXe.some(
                (e) => e.DanhSachChoDangChonTheoTang.length > 0,
            );
            return !check;
        },
    },
    watch: {
        PopupXacNhanBanVuotSoVeToiDa() {
            if (!this.PopupXacNhanBanVuotSoVeToiDa) {
                setTimeout(() => {
                    this.ifPopupXacNhanBanVuotSoVeToiDa = false;
                }, 300);
            }
        },
    },
    methods: {
        ...mapMutations("ChuyenDi", ["Set"]),
        showPopup(name) {
            this["if" + name] = true;
            this[name] = true;
        },
        async getData() {
            this.queryHeader = this.$route.query;
            try {
                this.DiemXuatPhat = null;
                this.DiemXuong = null;
                this.TangXe = [];
                this.TangXe.index = 0;
                this.$startLoading;
                let rsDanhSachTang = await this.$store.dispatch(
                    "ChuyenDi/Get_DanhSachTangXe",
                    this.queryHeader.maChuyenDi,
                );

                if (rsDanhSachTang.status) {
                    if (this.DanhSachTangXe.length > 0) {
                        this.TangXe = this.DanhSachTangXe[0];
                        this.TangXe.index = 0;
                        // this.ChonTangXe(this.TangXe, this.TangXe.index);
                    }
                }

                let rsDanhSachDiemDung = await this.$store.dispatch(
                    "ChuyenDi/Get_DanhSachDiemDungCuaLoTrinh",
                    {
                        guidLoTrinh: this.queryHeader.guidLoTrinh,
                    },
                );
                if (!rsDanhSachDiemDung.status) {
                    this.$Helper.ThongBaoToast(
                        "error",
                        "Lấy danh sách điểm dừng thất bại! " + rsDanhSachDiemDung.message,
                    );
                } else {
                    if (rsDanhSachDiemDung.data.length > 1) {
                        this.DiemXuatPhat = rsDanhSachDiemDung.data[0].guidDiemXuong;
                        this.DiemXuong =
                            rsDanhSachDiemDung.data[
                                rsDanhSachDiemDung.data.length - 1
                            ].guidDiemXuong;
                    }
                }
            } catch (error) {
            } finally {
                this.$stopLoading;
            }
        },
        ChonDiemXuatPhat(e) {
            if (this.DangChonTangXe || !e.value || this.DanhSachTangXe.length == 0) {
                return;
            }
            this.DangChonTangXe = true;
            this.DanhSachChoNgoiBanVe = [];
            this.DanhSachChoDangChonTheoXe.forEach((tang) => {
                tang.DanhSachChoDangChonTheoTang = [];
            });
            this.ChonTangXe(this.TangXe, this.TangXe.index);
        },
        ChonDiemXuong(e) {
            if (this.DangChonTangXe || !e.value || this.DanhSachTangXe.length == 0) {
                return;
            }
            this.DangChonTangXe = true;
            this.DanhSachChoNgoiBanVe = [];
            this.DanhSachChoDangChonTheoXe.forEach((tang) => {
                tang.DanhSachChoDangChonTheoTang = [];
            });
            this.ChonTangXe(this.TangXe, this.TangXe.index);
        },
        async ChonTangXe(item, index) {
            this.$startLoading;
            try {
                this.$store.commit("Ve/Set", {
                    key: "queryChoNgoi",
                    data: this.queryHeader,
                });
                this.TangXe = item;
                this.TangXe.index = index;
                this.DanhSachTangXe.map((e) => {
                    e.active = false;
                    return e;
                });
                this.DanhSachTangXe[index].active = true;
                let rs = await this.$store.dispatch(
                    "ChuyenDi/Get_MaTranChoNgoi",
                    {
                        IdChuyenDi: this.queryHeader.maChuyenDi,
                        IdTang: item.IdTang,
                        IndexTang: index,
                        IdDiemBatDau: this.DiemXuatPhat,
                        IdDiemKetThuc: this.DiemXuong,
                    },
                    // item: item,
                );

                if (rs.status) {
                } else {
                    this.$Helper.ThongBaoToast(
                        "error",
                        "Lấy sơ đồ chỗ thất bại! " + rs.message,
                    );
                }
                this.$stopLoading;
            } catch (error) {
                this.$stopLoading;
            } finally {
                this.DangChonTangXe = false;
            }
        },
        async BanVe() {
            this.DanhSachChoNgoiBanVe = [];
            try {
                this.$startLoading;
                for (
                    let indexTang = 0;
                    indexTang < this.DanhSachChoDangChonTheoXe.length;
                    indexTang++
                ) {
                    const tang = this.DanhSachChoDangChonTheoXe[indexTang];
                    for (
                        let indexCho = 0;
                        indexCho < tang.DanhSachChoDangChonTheoTang.length;
                        indexCho++
                    ) {
                        const cho = tang.DanhSachChoDangChonTheoTang[indexCho];
                        try {
                            let query = {
                                IdChuyenDi: this.queryHeader.maChuyenDi,
                                IdChoNgoi: cho.IdCho,
                                IdDiemXuatPhat: this.DiemXuatPhat,
                                IdDiemKetThuc: this.DiemXuong,
                            };
                            let rs = await this.$store.dispatch(
                                "ChuyenDi/LayGiaVeTheoChoNgoi",
                                query,
                            );
                            let data = {
                                TenCho: cho.TenCho,
                                idChoNgoi: cho.IdCho,
                                giaVe: rs.data.giaVeGoc || 0,
                                giaVeSauGiamTru: rs.data.giaVeSauGiamTru || null,
                            };
                            this.DanhSachChoNgoiBanVe.push(data);
                        } catch (error) {
                            console.log("🚀 ~ BanVe ~ error:", error);
                        }
                    }
                }

                let rsKiemTraChuyenDi = await this.$store.dispatch(
                    "ChuyenDi/KiemTraTrangThaiChuyenDi",
                    {
                        IdChuyenDi: this.queryHeader.maChuyenDi,
                        SoKhach: this.DanhSachChoNgoiBanVe.length,
                    },
                );
                if (!rsKiemTraChuyenDi.data) {
                    if (
                        this.$Helper.KiemTraQuyen(
                            this.$t("QuyenBanVe.BanVeTaiBen"),
                            this.$t("QuyenBanVe.BanVeVuotSoVeToiDa"),
                        ) &&
                        rsKiemTraChuyenDi.errorCode == 1268
                    ) {
                        this.showPopup("PopupXacNhanBanVuotSoVeToiDa");
                        return;
                    }
                    this.$Helper.ThongBaoPopup({
                        type: "Error",
                        message_title: "Bán vé gặp lỗi!",
                        message: rsKiemTraChuyenDi.message,
                    });
                    return;
                }

                this.ChuyenTrangBanVe();
            } catch (error) {
                console.log("🚀 ~ BanVe ~ error:", error);
            } finally {
                this.$stopLoading;
            }
        },
        XacNhanBanVuotSoVeToiDa(bool) {
            if (bool == true) {
                this.ChuyenTrangBanVe();
                this.PopupXacNhanBanVuotSoVeToiDa = false;
            } else if (bool == false) {
                this.PopupXacNhanBanVuotSoVeToiDa = false;
            }
        },
        ChuyenTrangBanVe() {
            this.Set({ key: "reloadThongTinBanVe", data: true });
            this.$router.push({
                path: "/Ban-Ve/Thong-Tin-Thanh-Toan",
                query: {
                    guidDoanhNghiep: this.queryHeader.idDoanhNghiep,
                    guidChuyenDi: this.queryHeader.maChuyenDi,
                    bienKiemSoat: this.queryHeader.bienKiemSoat,
                    gioXuatBenDuKien: this.queryHeader.gioXuatBenDuKien,
                    idDiemXuatPhat: this.DiemXuatPhatDangChon.guidDiemXuong,
                    tenDiemXuatPhat: this.DiemXuatPhatDangChon.tenDiemXuong,
                    idDiemDen: this.DiemXuongDangChon.guidDiemXuong,
                    tenDiemDen: this.DiemXuongDangChon.tenDiemXuong,
                },
            });
        },
        async BanVeGhePhu() {
            try {
                this.$startLoading;
                let query = {
                    IdChuyenDi: this.queryHeader.maChuyenDi,
                    IdChoNgoi: "00000000-0000-0000-0000-000000000000",
                    IdDiemXuatPhat: this.DiemXuatPhat,
                    IdDiemKetThuc: this.DiemXuong,
                };
                let rs = await this.$store.dispatch(
                    "ChuyenDi/LayGiaVeTheoChoNgoi",
                    query,
                );
                if (rs.status) {
                    this.Set({ key: "reloadThongTinBanVe", data: true });
                    this.$router.push({
                        path: "/Ban-Ve/Ban-Ve-Ghe-Phu",
                        query: {
                            giaVe: rs.data.giaVeGoc || 0,
                            giaVeSauGiamTru: rs.data.giaVeSauGiamTru || null,
                            guidDoanhNghiep: this.queryHeader.idDoanhNghiep,
                            guidChuyenDi: this.queryHeader.maChuyenDi,
                            bienKiemSoat: this.queryHeader.bienKiemSoat,
                            gioXuatBenDuKien: this.queryHeader.gioXuatBenDuKien,
                            idDiemXuatPhat: this.DiemXuatPhatDangChon.guidDiemXuong,
                            tenDiemXuatPhat: this.DiemXuatPhatDangChon.tenDiemXuong,
                            idDiemDen: this.DiemXuongDangChon.guidDiemXuong,
                            tenDiemDen: this.DiemXuongDangChon.tenDiemXuong,
                        },
                    });
                } else {
                    this.$Helper.ThongBaoToast(
                        "error",
                        "Lấy giá vé thất bại! " + rs.message,
                    );
                }
                this.$stopLoading;
            } catch (error) {
                this.$stopLoading;
                this.$Helper.ThongBaoToast("error", "Lấy giá vé thất bại! Lỗi hệ thống.");
            }
        },
    },
    created() {
        onIonViewWillEnter(() => {
            if (this.reloadData) {
                this.getData();
                this.Set({ key: "reloadData", data: false });
            }
            setTimeout(() => {
                this.emitter.emit("hiddenFloatBtn");
            }, 100);
        });
    },
};
</script>

<style scoped>
.frame-so-do-cho {
    padding: 0 16px;
    height: calc(100vh - 48px - 56px - 64px);
}
.frame-so-do-cho.xe-nhieu-tang {
    height: calc(100vh - 48px - 56px - 64px - 20px);
}
</style>
